<template>
  <div>
    <div class="flex gap-2 items-center">
      <div class="bg-gray-100 rounded-full flex items-center justify-center p-2 relative">
        <component :is="'fw-icon-' + fileIconKey" class="h-5 w-5 text-gray-500" />
        <fw-icon-checkbox-solid v-if="showAs === 'valid'" class="h-5 w-5 text-primary absolute -top-1.5 -right-1.5" />
        <fw-icon-close
          v-else-if="showAs === 'invalid'"
          class="h-5 w-5 p-0.5 text-white absolute -top-1.5 -right-1.5 rounded-full bg-red-500"
        />
      </div>
      <div class="flex-1">
        <a :href="fileUrl" class="inline-flex" @click.prevent="downloadFile">
          <v-clamp autoresize class="text-base font-medium text-gray-800" :max-lines="1">{{ fileTitle }}</v-clamp>
        </a>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {},
  props: {
    file: {
      type: Object,
      required: true
    },
    showAs: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      fileUrl: null
    }
  },

  computed: {
    fileTitle() {
      return this.file.title && this.file.title !== null
        ? this.file.title
        : this.file.filename
        ? this.file.filename
        : ''
    },
    fileIconKey() {
      const extension = this.$options.filters.getExtension(this.file.filename)
      const map = {
        pdf: 'file-pdf',
        png: 'image',
        jpeg: 'image',
        jpg: 'image'
      }
      if (!(extension in map)) {
        return 'file-alt'
      }
      return map[extension]
    }
  },

  created() {
    this.getFileUrl()
  },

  methods: {
    getFileUrl() {
      this.fileUrl = ServiceStorage.getFileUrl(this.file, this.$store.state.session.user.token)
    },
    downloadFile() {
      utils.downloadFile(this.fileUrl, this.file.filename)
    }
  }
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
